import AuthenticationService from "@/services/auth.service";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IRootState } from "../models";
import AuthenticationState, {
  IAuthenticationState,
} from "../models/auth-state";
import Token from "../models/token";
import Axios, { AxiosResponse } from "axios";
import UserService from "@/services/user.service";
import UserModel from "@/models/UserModel";
import { Role } from "@/common/enum";

const authState: IAuthenticationState = new AuthenticationState();

const getToken = (token: string): Promise<Token> => {
  return AuthenticationService.refreshToken(token).then(
    (response) => new Token(response)
  );
};

const getters: GetterTree<IAuthenticationState, IRootState> = {
  token: (state) => {
    const { token } = state;
    if (token.accessToken === "") {
      return new Token(localStorage.getItem("accessToken") || "");
    }
  },
  profile: (state, getters) => {
    const { profile } = state;
    return profile;
  },
  isAdmin: (state, getters) => {
    const { profile } = state;
    if (profile) {
      return profile.role === Role.Admin;
    }
    return true;
  },
};

const mutations: MutationTree<IAuthenticationState> = {
  setToken: (state, token: Token) => {
    state.token = token;
  },
  setProfile: (state, user: UserModel) => {
    state.profile = user;
  },
};

const actions: ActionTree<IAuthenticationState, IRootState> = {
  login: async (
    { commit, dispatch },
    data: { email: string; password: string }
  ) => {
    try {
      const accessToken: string = await AuthenticationService.login(
        data.email,
        data.password
      );
      const token = new Token(accessToken);
      await commit("setToken", token);
      localStorage.setItem("accessToken", accessToken);
      dispatch("getProfile");
    } catch (error) {
      return Promise.reject(error);
    }
  },

  logout: async ({ commit }) => {
    commit("setToken", new Token());
    localStorage.setItem("accessToken", "");
  },

  getProfile: async ({ commit }) => {
    const service = new UserService();
    try {
      const profile = await service.profile();
      commit("setProfile", profile);
    } catch (error) {
      return Promise.reject({ status: 401 });
    }
  },

  getToken: async ({ state, commit, dispatch }): Promise<string> => {
    const { token, profile } = state;
    if (token.accessToken === "") {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken && accessToken !== "") {
        const refreshedToken = await getToken(accessToken);
        await commit("setToken", refreshedToken);
        localStorage.setItem("accessToken", refreshedToken.accessToken);
        if (!profile) {
          dispatch("getProfile");
        }
        return Promise.resolve(refreshedToken.accessToken);
      }
      return Promise.reject({ status: 401 });
    } else {
      if (token.isExpired) {
        return Promise.reject({ status: 401 });
      }
      if (token.isTokenAboutToExpire) {
        const refreshedToken = await getToken(token.accessToken);
        commit("setToken", refreshedToken);
        localStorage.setItem("accessToken", refreshedToken.accessToken);
        return Promise.resolve(refreshedToken.accessToken);
      }
      return token.accessToken;
    }
  },
};

export const authModule: Module<IAuthenticationState, IRootState> = {
  state: authState,
  actions,
  mutations,
  getters,
  namespaced: true,
};
