import Axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
import store from "@/store";
import router from "@/router";

export type RequestType = "get" | "delete" | "post" | "put" | "patch";

export default class BaseService {
  protected axiosInstance = this.createAxiosInstance();
  /**
   * Create custom instance of Axios with custom changes
   *
   * @returns {AxiosInstance}
   */
  protected createAxiosInstance(): AxiosInstance {
    const axios = Axios.create({
      transformResponse: (data) => {
        let res = data;
        try {
          if (typeof data !== "string") {
            // if not string pass as it is.
            return data;
          }
          // else json parse the response
          res = JSON.parse(data);
        } catch (ex) {
          res = data;
        }
        return res;
      },
      validateStatus: (status: number) =>
        (status >= 200 && status < 300) || status === 401 || status === 400 || status === 403,
    });

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.baseURL = process.env.VUE_APP_API_URL;

      try {
        const token: string = await store.dispatch("auth/getToken");
        if (config.headers) {
          config.headers["x-access-token"] = token;
        }
        return Promise.resolve(config);
      } catch (error) {
        return Promise.reject(error);
      }
    });

    axios.interceptors.response.use(
      (res: AxiosResponse<any>) => {
        if (res.status === 401) {
          if (router.currentRoute.name !== "Login") {
            router.push({
              name: "Login",
            });
          }
          return Promise.reject({ status: res.status, error: res.statusText });
        }

        if (res.status === 403) {
          return Promise.reject({ status: res.status, error: res.statusText });
        }

        if (res.status === 400) {
          return Promise.reject({ status: res.status, error: res.data });
        }

        return Promise.resolve(res);
      },
      (error: any) => {
        if (error.error) {
          return Promise.reject({ status: 500, error: error.error });
        }

        return Promise.reject({
          status: error.response.status,
          error: `${error.response.status}: ${error.response.statusText}`,
        });
      }
    );

    return axios;
  }
}
