export enum Role {
  Admin = 1,
  User = 2,
}
export enum Status {
  Pending = 0,
  Validated = 1,
  Active = 2,
  Inactive = 3,
}
