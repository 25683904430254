var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{attrs:{"absolute":"","left":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Eklavya")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},_vm._l((_vm.navigationItems()),function(item,index){return _c('v-list-item',{key:`navigation-item-${index}`,class:{
        primary: item.isActive,
      },attrs:{"link":"","replace":"","to":item.route}},[_c('v-list-item-icon',[_c('v-icon',{class:{
            'white--text': item.isActive,
          }},[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
            'white--text': item.isActive,
          }},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }