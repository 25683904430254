
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "NavigationDrawer",
})
export default class NavigationDrawer extends Vue {
  @Prop({ default: () => false })
  public value = false;

  private drawer = false;

  @Watch("drawer", { deep: true })
  onDrawerCollapse(): void {
    if (this.value !== this.drawer) {
      this.$emit("input", this.drawer);
    }
  }

  @Watch("value")
  public onDrawerVisibilityChange(): void {
    this.drawer = this.value;
  }

  private navigationItems(): Array<{
    icon: string;
    title: string;
    route: string;
    isActive: boolean;
  }> {
    return [
      {
        icon: "mdi-home",
        title: "Home",
        route: "/",
        isActive: this.$router.currentRoute.path === "/",
      },
      {
        icon: "mdi-account-circle",
        title: "Users",
        route: "/users",
        isActive: this.$router.currentRoute.path === "/accounts",
      },
    ];
  }

  private isActive(path: string) {
    return this.$router.currentRoute.path === path;
  }

  private logout() {
    this.$store.dispatch("auth/logout").then(() => {
      this.$router.push({
        name: "Login",
      });
    });
  }
}
