import axios from "axios";
import BaseService from "./base.service";

export default class AuthenticationService {
  public static login = async (
    email: string,
    password: string
  ): Promise<string> => {
    return axios
      .post(
        "/auth/login",
        { email, password },
        {
          baseURL: process.env.VUE_APP_API_URL,
          validateStatus: (status: number) => status >= 200 && status < 300,
        }
      )
      .then((response) => response.data.data)
      .catch((error) => Promise.reject({ status: 401 }));
  };
  public static refreshToken = async (token?: string): Promise<string> => {
    return axios
      .get("/auth/renew-token", {
        params: token
          ? {
              token,
            }
          : undefined,
        baseURL: process.env.VUE_APP_API_URL,
        validateStatus: (status: number) => status >= 200 && status < 300,
      })
      .then((response) => response.data.data)
      .catch((error) => Promise.reject({ status: 401 }));
  };
}
