import moment from "moment";

export default class Token {
  public accessToken!: string;
  public expireOn!: moment.Moment;

  public get isExpired(): boolean {
    return moment(this.expireOn).isBefore(moment.now());
  }

  public get isTokenAboutToExpire(): boolean {
    return moment(this.expireOn).diff(moment.now()) < 300000;
  }

  constructor(token?: string) {
    if (token) {
      this.accessToken = token;
      this.expireOn = moment().add("1", "hour");
    } else {
      this.accessToken = "";
      this.expireOn = moment();
    }
  }
}
