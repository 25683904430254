import UserModel, { AddUserModel } from "@/models/UserModel";
import BaseService from "./base.service";
import { AxiosResponse } from "axios";
import PagedResult from "@/models/PagedResult";
import ResponseModel from "@/models/ResponseModel";

export default class UserService extends BaseService {
  profile = async (): Promise<UserModel> => {
    try {
      const response: AxiosResponse<ResponseModel<UserModel>> =
        await this.axiosInstance.get(`/user/profile`);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  getAll = async (
    filterData: Record<string, string | number | boolean>
  ): Promise<PagedResult<UserModel>> => {
    try {
      const response: AxiosResponse<ResponseModel<PagedResult<UserModel>>> =
        await this.axiosInstance.get("/user", {
          params: filterData,
        });
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  getById = async (id: number): Promise<UserModel> => {
    try {
      const response: AxiosResponse<ResponseModel<UserModel>> =
        await this.axiosInstance.get(`/user/${id}`);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  create = async (data: FormData): Promise<UserModel> => {
    try {
      const response: AxiosResponse<ResponseModel<UserModel>> =
        await this.axiosInstance.post(`/user/create`, data);
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  update = async (data: UserModel): Promise<UserModel> => {
    const updateData = Object.assign(new UserModel(), data);
    try {
      const response: AxiosResponse<ResponseModel<UserModel>> =
        await this.axiosInstance.patch(
          `/user/${updateData.id}`,
          updateData.dto
        );
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  delete = async (id: number): Promise<boolean> => {
    try {
      const response: AxiosResponse<ResponseModel<void>> =
        await this.axiosInstance.delete(`/user/${id}`);
      return response.status >= 200 && response.status < 300;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  changeStatus = async (
    userId: number,
    status: 0 | 1 | 2 | 3
  ): Promise<boolean> => {
    try {
      const response: AxiosResponse<ResponseModel<void>> =
        await this.axiosInstance.patch(`/user/status/${userId}`, { "status": status });
      return response.status >= 200 && response.status < 300;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
