import Vue from "vue";
import Vuex from "vuex";
import RootState, { IRootState } from "./models";
import { authModule } from "./modules/auth-module";

Vue.use(Vuex);

export default new Vuex.Store<IRootState>({
  state: new RootState(),
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
  },
});
