
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class Users extends Vue {
  public headers = [
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "Email Address",
      value: "email",
    },
    {
      text: "Phone number",
      value: "phoneNumber",
    },
    {
      text: "Role",
      value: "role",
    },
    {
      text: "Device Id",
      value: "deviceid"
    },
    {
      text: "Status",
      value: "status",
    },
    // {
    //   text: "Actions",
    //   value: "actions",
    // },
  ];
  public itemsPerPageOptions = [10, 20, 25, 50];
  public options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
  } = {
    page: 1,
    itemsPerPage: this.itemsPerPageOptions[0],
    sortBy: ["id"],
    sortDesc: [true],
  };
  

  
 
}
