
import { Vue, Component, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import UserModel, { UserModelWithDeviceDTO } from "@/models/UserModel";
import Validations from "@/common/validations";
import { Role, Status } from "@/common/enum";

@Component
export default class Users extends Vue {
  private userService!: UserService;
  public users: UserModel[] = [];
  public dialog = false;
  public dialogDelete = false;
  public userStatus = "";
  public snackbar = false;
  public snackColor = "";
  public validations = Validations;
  public valid = true;
  public roles = [
    {
      text: "Admin",
      value: Role.Admin,
    },
    {
      text: "User",
      value: Role.User,
    },
  ];
  public statuses = [
    {
      text: "Pending",
      value: Status.Pending,
    },
    {
      text: "Validated",
      value: Status.Validated,
    },
    {
      text: "Active",
      value: Status.Active,
    },
    {
      text: "Inactive",
      value: Status.Inactive,
    },
  ];
  public statusesOptions = [
    {
      text: "Pending",
      value: Status.Pending,
    },
    {
      text: "Active",
      value: Status.Active,
    },
    {
      text: "Inactive",
      value: Status.Inactive,
    },
  ];
  public gender = [
    {
      text: "Male",
      value: "Male",
    },
    {
      text: "Female",
      value: "Female",
    },
    {
      text: "Other",
      value: "Other",
    },
  ];
  public headers = [
    {
      text: "Id",
      value: "id",
    },
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "Email Address",
      value: "email",
    },
    {
      text: "Phone number",
      value: "phoneNumber",
    },
    {
      text: "Role",
      value: "role",
    },
    {
      text: "Device Id",
      value: "user_device.deviceId",
    },
    {
      text: "Status",
      value: "status",
    },
    {
      text: "Actions",
      value: "actions",
    },
  ];
  public editedItem: UserModel = this.defaultItem;
  public loading = false;
  public itemsPerPageOptions = [10, 20, 25, 50];
  public options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
  } = {
    page: 1,
    itemsPerPage: this.itemsPerPageOptions[0],
    sortBy: ["id"],
    sortDesc: [true],
  };
  public search = "";
  public totalUsers = 0;
  public token = "";

  constructor() {
    super();
    this.userService = new UserService();
  }

  private async mounted() {
    this.token = await this.$store.dispatch("auth/getToken");
  }

  get defaultItem(): UserModel {
    const item = new UserModel();
    item.role = Role.User;
    return item;
  }

  get formTitle(): string {
    return this.isEdit ? "Edit User" : "Add User";
  }

  get isEdit(): boolean {
    return this.editedItem.id ? true : false;
  }

  get isUserAdmin(): boolean {
    return this.$store.getters["auth/isAdmin"];
  }

  public async onSearch(): Promise<void> {
    this.options = {
      ...this.options,
      page: 1,
    };
  }

  public async getAllUsers(): Promise<void> {
    try {
      const filterData: Record<string, string | number | boolean> = {};
      filterData.page = this.options.page || 1;
      filterData.limit = this.options.itemsPerPage || 10;
      if (this.search) {
        filterData.search = this.search;
      }
      filterData.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "id";
      filterData.sortOrder =
        this.options.sortDesc.length > 0
          ? this.options.sortDesc[0]
            ? "desc"
            : "asc"
          : "asc";

      const response = await this.userService.getAll(filterData);
      this.users = response.rows;
      this.totalUsers = response.count;
    } catch (error) {
      this.userStatus = "failed to load user data";
      this.snackColor = "red";
      this.snackbar = true;
    }
  }

  @Watch("dialog")
  onDialog(val: boolean): void {
    val || this.close();
  }
  @Watch("dialogDelete")
  onDialogDelete(val: boolean): void {
    val || this.closeDelete();
  }

  @Watch("options", { deep: true })
  onChangeOptions(): void {
    this.getAllUsers();
  }

  editItem(item: UserModelWithDeviceDTO): void {
    this.editedItem = Object.assign(new UserModel(), item);
    this.editedItem.deviceId = item.user_device.deviceId;
    this.dialog = true;
  }

  public async updateStatus(
    itemId: number,
    selectedStatus: 0 | 1 | 2 | 3
  ): Promise<void> {
    try {
      await this.userService.changeStatus(itemId, selectedStatus);
      await this.getAllUsers();
    } catch (error) {
      this.userStatus = "failed to change status";
      this.snackColor = "red";
      this.snackbar = true;
    }
  }

  deleteItem(item: UserModel): void {
    this.editedItem = Object.assign(new UserModel(), item);
    this.$nextTick(() => {
      this.dialogDelete = true;
    });
  }
  async deleteItemConfirm(): Promise<void> {
    this.loading = true;
    try {
      await this.userService.delete(this.editedItem.id);
      this.closeDelete();
      if (this.users.length === 1) {
        this.options = {
          ...this.options,
          page: this.options.page > 0 ? this.options.page - 1 : 0,
        };
      } else {
        this.getAllUsers();
      }
    } catch (error) {
      this.userStatus = "failed to delete user";
      this.snackColor = "red";
      this.snackbar = true;
    } finally {
      this.loading = false;
    }
  }
  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign(new UserModel(), this.defaultItem);
    });
  }
  closeDelete(): void {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign(new UserModel(), this.defaultItem);
    });
  }
  async save(): Promise<void> {
    const validate = await (this.$refs.form as any)?.validate();

    if (!validate) {
      return;
    }
    this.loading = true;
    try {
      if (this.isEdit) {
        await this.userService.update(this.editedItem);
      } else {
        const formData = new FormData();
        const addData = this.editedItem.addDTO;
        formData.append("firstName", addData.firstName);
        formData.append("lastName", addData.lastName);
        formData.append("email", addData.email);
        formData.append("role", addData.role.toString());
        formData.append("telegramId", addData.telegramId.toString());
        formData.append("phoneNumber", addData.phoneNumber);
        formData.append("password", addData.password);
        formData.append("idCard", addData.idCard);
        formData.append("govId", addData.govId);
        formData.append("gender", addData.gender);
        formData.append("state", addData.state);
        formData.append("department", addData.department);
        formData.append("designation", addData.designation);
        formData.append("placeOfDuty", addData.placeOfDuty);
        formData.append("supervisorName", addData.supervisorName);
        formData.append("supervisorEmail", addData.supervisorEmail);
        formData.append("deviceId", addData.deviceId);
        await this.userService.create(formData);
      }
      this.close();
      this.getAllUsers();
    } catch (error) {
      // Handle Error
      this.userStatus = "failed to save user";
      this.snackColor = "red";
      this.snackbar = true;
    } finally {
      this.loading = false;
    }
  }
}
