
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import NavigationDrawer from "@/components/NavigationDrawer.vue";

@Component({
  name: "App",
  components: {
    NavigationDrawer,
  },
})
export default class App extends Vue {
  private drawer = false;

  private get navVisible(): boolean {
    return this.$route.name
      ? !["Login", "Logout"].includes(this.$route.name)
      : false;
  }

  private get title(): string {
    return this.$route.name || "Home";
  }
}
