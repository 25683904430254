import UserModel from "@/models/UserModel";
import Token from "./token";

export interface IAuthenticationState {
  token: Token;
  profile: UserModel | null;
}

export default class AuthenticationState implements IAuthenticationState {
  public token = new Token();
  public profile = null;
}
