import { Role, Status } from "@/common/enum";

export interface UserModelDTO {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: number;
  telegramId: number;
  idCard: string;
  govId: string;
  gender: string;
  state: string;
  department: string;
  designation: string;
  placeOfDuty: string;
  supervisorName: string;
  supervisorEmail: string;
  deviceId: string;
  status: Status;
}

export interface UserDeviceModel {
  id: number;
  userId: number;
  deviceId: string;
  otp?: string;
}

export interface UserModelWithDeviceDTO extends UserModel {
  user_device: UserDeviceModel;
}

export interface AddUserModel extends UserModelDTO {
  password: string;
}

export default class UserModel {
  public id!: number;
  public firstName!: string;
  public lastName!: string;
  public email!: string;
  public phoneNumber!: string;
  public role!: Role;
  public telegramId!: number;
  public password!: string;
  public idCard!: string;
  public govId!: string;
  public gender!: string;
  public state!: string;
  public department!: string;
  public designation!: string;
  public placeOfDuty!: string;
  public supervisorName!: string;
  public supervisorEmail!: string;
  public deviceId!: string;
  public status!: Status;

  get idCardUrl(): string {
    return `${process.env.VUE_APP_API_URL}/document/${this.idCard}`;
  }

  get dto(): UserModelDTO {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      role: this.role,
      telegramId: this.telegramId,
      idCard: this.idCard,
      govId: this.govId,
      gender: this.gender,
      state: this.state,
      department: this.department,
      designation: this.designation,
      placeOfDuty: this.placeOfDuty,
      supervisorName: this.supervisorName,
      supervisorEmail: this.supervisorEmail,
      deviceId: this.deviceId,
      status: this.status,
    };
  }

  get addDTO(): AddUserModel {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      role: this.role,
      telegramId: this.telegramId,
      password: this.password,
      idCard: this.idCard,
      govId: this.govId,
      gender: this.gender,
      state: this.state,
      department: this.department,
      designation: this.designation,
      placeOfDuty: this.placeOfDuty,
      supervisorName: this.supervisorName,
      supervisorEmail: this.supervisorEmail,
      deviceId: this.deviceId,
      status: this.status,
    };
  }
}
