
import Token from "@/store/models/token";
import { Vue, Component } from "vue-property-decorator";
import Validations from "@/common/validations";

@Component
export default class Login extends Vue {
  private showPass = false;
  private email = "";
  private password = "";
  public loginStatus = "";
  public snackbar = false;
  public snackColor = "";
  public validations = Validations;
  private async mounted() {
    try {
      const token: string = await this.$store.dispatch("auth/getToken");
      if (token !== "") {
        this.$router.push({
          name: "Home",
        });
      }
    } catch (error) {
      this.loginStatus = "you are not authorized please login again";
      this.snackColor = "red";
      this.snackbar = true;
    }
  }

  private login() {
    this.$store
      .dispatch("auth/login", {
        email: this.email,
        password: this.password,
      })
      .then((response) => {
        this.loginStatus = "login Success";
        this.snackColor = "green";
        this.snackbar = true;
        this.$router.push({
          name: "Home",
        });
      })
      .catch((error) => {
        if (typeof error === "object" && "status" in error) {
          if (error.status === 401) {
            this.loginStatus = "Invalid credentials";
            this.snackColor = "red";
            this.snackbar = true;
          }
        }
      });
  }
}
